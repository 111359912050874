import "../../dev/scss/index.scss";
import yall from "yall-js";

document.addEventListener("DOMContentLoaded", yall);

$(document).ready(() => {

    let win = $(window);
    let DOM = $('body');
    let player = $('video');

    let kinoNavMenu = $('.kino-nav-menu').get(0);
    let kinoNavMenuFixed = $('.kino-nav-menu.fixed');
    let kinoNavMenuFixedOpen = $('.kino-nav-menu-fixed-open');
    let kinoNavMenuFixedClose = $('.kino-nav-menu-fixed-close');

    let kinoCarouselTop = $('.kino-carousel-top');
    let kinoCarouselCategories = $('.kino-carousel-categories');

    let kinoNewsCarosel = $('.kino-news-carousel');

    let log = msg => {
        // console.log(msg)
    };
    let isOverflown = element => element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

    /** window */
    win.on('resize', onWindowResize);

    /** nav */
    kinoNavMenuFixedOpen.on('click', () => {
        kinoNavMenuFixed.removeClass('kino-hidden');
        DOM.css('overflow', 'hidden');
    });
    kinoNavMenuFixedClose.on('click', () => {
        kinoNavMenuFixed.addClass('kino-hidden');
        DOM.css('overflow', 'auto');
    });

    /** Ads */
    DOM.one('click', '.kino-ads-container', e => {

        let container = $(e.currentTarget);
        let counter = 0;
        try {
            let vpaid = new VPAID({

                container: container.get(0),
                hash: 'xAxZXaHJVrMJSEW2', // kinotrain

                GUI: {
                    controls: false,
                    timer: false,
                    title: true
                },

                callback: function (event) {
                    log(`${event}`);
                    if (event === 'AdStopped') {
                        container.remove();
                        player.get(0).play();
                    }
                }
            });
            vpaid.init();

            log('Call ad.');
        }catch (e) {
            log('Can not start ad module');
            container.remove();
            player.get(0).play();
        }

    });

    // all hrefs
    DOM.on('click', '.kino-item, .kino-carousel-top .item', function (e) {
        let a = $(this).find('.name a');
        if (a && a.attr('href')){
            window.open(a.attr('href'), '_self');
        }
    });

    /** carousels */
    kinoCarouselCategories.owlCarousel({
        margin: 20,
        loop:true,
        items: 5,
        nav: true,
        // center: true,
        responsive: {
            0: {
                items: 1
            },
            360:{
                items: 2
            },
            576: {
                items: 3
            },
            1024:{
                items: 5
            },
            1366:{
                items: 5
            },
            1800:{
                items: 7
            }
        }
    });
    kinoNewsCarosel.owlCarousel({
        margin: 20,
        loop:true,
        items: 3,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            1024:{
                items: 3
            }
        }
    });
    let kinoCarouselTopOwl = kinoCarouselTop.owlCarousel({
        margin:0,
        loop:true,
        items: 5,
        nav: true,
        center: true,
        responsive: {
            0: {
                items: 1
            },
            320:{
                items: 2,
                // center: false
            },
            576: {
                items: 3
            },
            992:{
                items: 5
            },
            1366:{
                items: 7
            },
            1800:{
                items: 7
            }
        }
    });
    // all single
    $('.kino-carousel-categories-single').owlCarousel({
        margin: 20,
        loop:true,
        items: 5,
        nav: true,
        // center: true,
        responsive: {
            0: {
                items: 1
            },
            360:{
                items: 2
            },
            576: {
                items: 3
            },
            1024:{
                items: 5
            },
            1366:{
                items: 5
            }
        }
    });
    // all carousels
    DOM.on('click', '.kino-carousel-nav.next', function() {
        $(this).parent().find('.owl-nav .owl-next').trigger('click');
        topCarouselDark();
    });
    DOM.on('click', '.kino-carousel-nav.prev', function() {
        $(this).parent().find('.owl-nav .owl-prev').trigger('click');
        topCarouselDark();
    });

    kinoCarouselTopOwl.on('changed.owl.carousel', function(event) {
        topCarouselDark();
    });

    //  darker
    function topCarouselDark() {
        log(`topCarouselDark()`);
        kinoCarouselTop.find('.owl-item .poster-darker').css('opacity', 0);

        kinoCarouselTop.find('.owl-item.active.center').next().find('.poster-darker').css('opacity', 0.2);
        kinoCarouselTop.find('.owl-item.active.center').next().next().find('.poster-darker').css('opacity', 0.4);
        kinoCarouselTop.find('.owl-item.active.center').next().next().next().find('.poster-darker').css('opacity', 0.6);
        kinoCarouselTop.find('.owl-item.active.center').next().next().next().next().find('.poster-darker').css('opacity', 0.8);
        kinoCarouselTop.find('.owl-item.active.center').prev().find('.poster-darker').css('opacity', 0.2);
        kinoCarouselTop.find('.owl-item.active.center').prev().prev().find('.poster-darker').css('opacity', 0.4);
        kinoCarouselTop.find('.owl-item.active.center').prev().prev().prev().find('.poster-darker').css('opacity', 0.6);
        kinoCarouselTop.find('.owl-item.active.center').prev().prev().prev().prev().find('.poster-darker').css('opacity', 0.8);
    }



    // additional functions
    function onWindowResize(){
        if (isOverflown(kinoNavMenu)){
            log('Nav menu overflow.');
            kinoNavMenu.classList.add('overflow');
            kinoNavMenuFixedOpen.removeClass('kino-hidden');
        }else{
            log('Nav menu is not overflow.');
            kinoNavMenu.classList.remove('overflow');
            kinoNavMenuFixedOpen.addClass('kino-hidden');
        }
    }

    log(`DOM ready.`);

    onWindowResize();
    topCarouselDark();

    setTimeout(() => {
       DOM.animate({
           opacity: 1
       }, 200);
    }, 300);
});